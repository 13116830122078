import http from "../common-http";

class AuthService {

  login(data: any){
    return http.post("/auth/token/", data);
  }
  
  getauth() {
    return http.get("/api/auth-user/");
  }


  getAll() {
    return http.get("/hrm/employee/");
  }

  get(id: string) {
    return http.get(`/hrm/employee//${id}`); 
  }

  create(data: any) {
    return http.post("/hrm/employee/", data);
  }

  update(id: string, data: any) {
    return http.put(`/hrm/employee//${id}`, data);
  }

  delete(id: string) {
    return http.delete(`/hrm/employee//${id}`);
  }

  deleteAll() {
    return http.delete(`/hrm/employee/`);
  }

  findByTitle(title: string) {
    return http.get(`/hrm/employee/?title=${title}`);
  }
}

export default new AuthService();