














































import { Component, Vue } from "vue-property-decorator";
import AuthService from "../../services/auth-service";

@Component({
  components: {},
})
export default class Employee extends Vue {
  private data: any[] = [];
  private title: string = "";
  form = {};
  error = "";

  public items = [];

  handleSubmit() {
    this.error = "";
    AuthService.login(this.form)
      .then((response) => {
        if (response) {
          localStorage.setItem("jwt", response.data.token);
          if (localStorage.getItem("jwt") != null) {
            this.$emit("loggedIn");
            if (this.$route.params.nextUrl != null) {
              this.$router.push(this.$route.params.nextUrl);
            } else {
              this.$router.push("employee");
            }
          }
        }
      })
      .catch((e) => {
        this.error = "Invalid Credentials";
        console.log(e);
      });
  }

  mounted() {}
}
